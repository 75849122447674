export const esFinishOrder = {
  address: "Dirección",
  amountCart: "Carrito",
  amountTip: "Propina",
  back: "Volver",
  continue: "Continuar",
  confirmOrder: "Confirmar Pedido",
  productsQty: "Cant. Productos",
  totalToPay: "Total a pagar",
  deliveryTime: "Hora de entrega",
  sooner: "Lo antes posible",
  noAvailability: "No quedan turnos por hoy",
};
